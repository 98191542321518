<template lang="pug">
    p reset page
</template>

<script>
export default {
    mounted() {
        localStorage.clear();
    }
}
</script>